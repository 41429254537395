import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoAccess.css';

const NoAccess = () => (
  <div className="NoAccess">
    <div className="no-access-container">
      <div className="no-access-title">
        You do not have access.
      </div>
    </div>
  </div>
);

NoAccess.propTypes = {};

NoAccess.defaultProps = {};

export default NoAccess;
