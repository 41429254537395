import React from "react";
import "./App.css";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { useAuthState, userAuthState } from "react-firebase-hooks/auth";
import NoAccess from "./components/NoAccess/NoAccess";
import { ChatRoom } from "./ChatRoom";

firebase.initializeApp({
  apiKey: "AIzaSyDJt375ZA67xknF7Zm-gbFhfhtXfr4gPjc",
  authDomain: "authentication-47be2.firebaseapp.com",
  databaseURL: "https://authentication-47be2.firebaseio.com",
  projectId: "authentication-47be2",
  storageBucket: "authentication-47be2.appspot.com",
  messagingSenderId: "16007967951",
  appId: "1:16007967951:web:390371b297fb10ed81f3ba",
  measurementId: "G-1VWLDWXF6B",
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();

function App() {
  const [user] = useAuthState(auth);

  // ⚛️🔥💬
  return <div className="App">{user ? Default(user) : <SignIn></SignIn>}</div>;
}

function Default(user) {
  return (
    <>
      <div className="app-container">
        <header>
          <div className="navbar-title  linear-wipe title-size">
            Mutinerie Chat
          </div>
          <div className="navbar-title">{getCurrentUserInfosText(user)}</div>
          <SignOut />
          {/* <button onClick={requestAccess}>Ask for access</button> */}
        </header>
        <section className="animated-gradient-bg">
          {user ? <ChatRoom /> : <SignIn />}
        </section>
      </div>
    </>
  );
}

function getCurrentUserInfosText(user) {
  var s = user.displayName + " ~ " + user.email;
  return s;
}

function requestAccess() {
  var usersRef = firebase.firestore().collection("/users");
  usersRef.get().then(function (snapshot) {
    var users = [];

    snapshot.forEach(function (childSnapshot) {
      var id = auth.currentUser.uid;

      users.push({ uid: id });
    });
  });
  alert("Requested access");
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <div className="sign-in-container">
      <div className="side-by-side">
        <button
          className="sign-in custom-button navbar-title"
          onClick={signInWithGoogle}
        >
          Se connecter avec Google
        </button>
      </div>
    </div>
  );
}

function SignOut() {
  return (
    auth.currentUser && (
      <button className="sign-out custom-button" onClick={() => auth.signOut()}>
        Déconnexion
      </button>
    )
  );
}

function playSound(url) {
  const audio = new Audio(url);
  audio.play();
}

export function getLinkMessage(link, messageClass, photoURL) {
  if (link !== undefined && link !== null) {
    return (
      <div className={`message ${messageClass} `}>
        <img
          src={
            photoURL || "https://api.adorable.io/avatars/23/abott@adorable.png"
          }
        />
        <div className="top-bottom-group">
          <p>
            <a href={link} className="">
              {link}
            </a>
          </p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export function getUrl(text) {
  var testUrl = text.match(/'(https?:[^\s]+)'/),
    onlyUrl = testUrl && testUrl[1];
  return onlyUrl;
}

function removeUrl(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return text.replace(url, "");
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function getExpireInTimeText(timeInMinutes) {
  let s = "";
  let pluriel = timeInMinutes > 1 ? "s" : "";

  if (timeInMinutes < 60) {
    s = "Expiration: " + timeInMinutes + " min" + pluriel;
  } else {
    let h = timeInMinutes / 60;
    pluriel = h > 1 ? "s" : "";
    s = "Expiration: " + h + " heure" + pluriel;
  }

  return s;
}

export function getMessageClass(expiredInMins) {
  let s = "time-container";
  if (expiredInMins < 20) {
    s = "time-expire-soon-container";
  }

  return s;
}

export function roundVal(v) {
  return Math.round((v + Number.EPSILON) * 100) / 100;
}

export function getDateTimeDiffWithNow(date1) {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  var diff = Math.abs(new Date(date1) - new Date(dateTime));

  return diff;
}

export default App;
