import React, { useRef, useState } from "react";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { ChatMessage } from "./ChatMessage";
import { firestore, auth } from "./App";

export function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection("messages");
  const roomsRef = firestore.collection("rooms");

  //const currentRoom = "main";
  //.where("room", "==", "currentRoom")
  const query = messagesRef.orderBy("createdAt").limit(25);
  const roomsQuery = roomsRef.limit(1);

  let [messages] = useCollectionData(query, { idField: "id" });
  let [rooms] = useCollectionData(roomsQuery, {
    expirationTime: "expirationTime",
  });

  let currentRoom = undefined;
  if (rooms !== undefined) {
    currentRoom = rooms[0];
  }

  let hasAccess = true;

  if (messages === undefined || messages === null) {
    hasAccess = false;
  }
  const [formValue, setFormValue] = useState("");
  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    await messagesRef.add({
      text: formValue,
      room: currentRoom,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdTime: dateTime,
      uid,
      photoURL,
    });
    if (hasAccess) {
      setFormValue("");
      dummy.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("Vous n'avez pas accès.");
    }
  };

  if (hasAccess && currentRoom !== undefined) {
    console.log(messages);
    return (
      <>
        <main>
          {messages &&
            messages.map((msg) => (
              <ChatMessage
                key={msg.id}
                room={currentRoom}
                message={msg}
                isLast
              />
            ))}

          <span ref={dummy}></span>
        </main>

        <div className="typing-bar-container">
          <form onSubmit={sendMessage}>
            <input
              value={formValue}
              onChange={(e) => setFormValue(e.target.value)}
              placeholder="Commencez à écrire.."
            />

            <button
              type="submit"
              className="custom-button send-message-button"
              disabled={!formValue}
            >
              Envoyer
            </button>
            <DeleteAllButton></DeleteAllButton>
          </form>
        </div>
      </>
    );
  } else {
    return (
      <div className="popux-box">
        <div>Vous n'avez pas accès.</div>
      </div>
    );
  }
}

function DeleteAllButton() {
  return (
    auth.currentUser && (
      <button
        className="sign-out custom-button"
        onClick={() => DeleteAllMessages()}
      >
        Supprimer Tout
      </button>
    )
  );
}

function DeleteAllMessages() {
  alert("Suppression de tous les messages");
  firebase
    .firestore()
    .document("/messages")
    .listDocuments()
    .then((val) => {
      val.map((val) => {
        val.delete();
      });
    });
}
