import React from "react";
import {
  auth,
  getDateTimeDiffWithNow,
  roundVal,
  getExpireInTimeText,
  getUrl,
  getMessageClass,
  getLinkMessage,
} from "./App";

export function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? "sent" : "received";
  let createdTime = props.message.createdTime;
  //console.log(props);
  let expirationTime = props.room.expirationTime;

  if (props.room === undefined) console.log("props.room is undefined");

  // 1440 = 24 heures
  // 720 = 12 heures
  let expiringTimeInMins = expirationTime;
  console.log(expiringTimeInMins);
  let expiringTimeInMs = expiringTimeInMins * 60000;

  let expired = false;
  let diff = getDateTimeDiffWithNow(createdTime);
  if (createdTime === null || createdTime === undefined) {
    expired = true;
  } else {
    expired = diff > expiringTimeInMs ? true : false;
  }

  let diffInMinutes = diff / 60000;
  diffInMinutes = roundVal(diffInMinutes);

  let expiredInMins = expiringTimeInMins - diffInMinutes;
  expiredInMins = roundVal(expiredInMins);

  let expireInText = getExpireInTimeText(expiredInMins);

  //localStorage.setItem("lastMessage",
  if (expired === true) {
    return <> </>;
  } else {
    //alert("Full Text: " + text);
    let link = getUrl(text);
    //alert("URL: " + link);
    let messageText = text.replace(link, "");
    //alert("Text After remove: " + messageText);
    return (
      <>
        <div>
          <div className={`message ${messageClass} `}>
            <img
              src={
                photoURL ||
                "https://api.adorable.io/avatars/23/abott@adorable.png"
              }
            />{" "}
            <div className="top-bottom-group">
              <div className={getMessageClass(expiredInMins)}>
                {" "}
                {expireInText}{" "}
              </div>{" "}
              <p> {messageText} </p>{" "}
            </div>{" "}
          </div>{" "}
          {getLinkMessage(link, messageClass, photoURL)}{" "}
        </div>{" "}
      </>
    );
  }
}
